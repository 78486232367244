<template>
  <v-footer class="mt-10 pa-0">
    <v-card tile width="100%" class="hidden-sm-and-down text-center py-4" style="background-color: var(--v-primary-base)">
      <v-row class="mx-16 justify-space-between align-center">
        <v-col cols="12" md="4">
          <div><strong style="font-size:large">Morada</strong> Rua Dr. Carlos Pires Felgueiras 98 2ºC 4470-157 Maia
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <strong>Copyright © {{ new Date().getFullYear() }} Zirconite de Negocios SL</strong>
        </v-col>
        <v-col cols="12" md="4">
          <div>
            <router-link to="/ig">
              <v-img style="display:inline-block" src="@/assets/ig.webp" max-width="50px" class="mx-2"></v-img>
            </router-link>
            <router-link to="/li">
              <v-img style="display:inline-block" src="@/assets/li.webp" max-width="50px" class="mx-2"></v-img>
            </router-link>
            <router-link to="/fb">
              <v-img style="display:inline-block" src="@/assets/fb.webp" max-width="50px" class="mx-2"></v-img>
            </router-link>
          </div>
        </v-col>
      </v-row>



    
    </v-card>
    <v-card tile width="100%" class="hidden-md-and-up text-center py-4" style="background-color: var(--v-primary-base)">
      <v-row class="mx-16 justify-space-between align-center">
          <v-col cols="12" md="4">
            <div><strong style="font-size:large">Morada</strong> Rua Dr. Carlos Pires Felgueiras 98 2ºC 4470-157 Maia</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <router-link to="/ig">
                <v-img style="display:inline-block" src="@/assets/ig.webp" max-width="50px" class="mx-2"></v-img>
              </router-link>
              <router-link to="/li">
                <v-img style="display:inline-block" src="@/assets/li.webp" max-width="50px" class="mx-2"></v-img>
              </router-link>
              <router-link to="/fb">
                <v-img style="display:inline-block" src="@/assets/fb.webp" max-width="50px" class="mx-2"></v-img>
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <strong>Copyright © {{ new Date().getFullYear() }} Zirconite de Negocios SL</strong>
          </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({}),
};
</script>
<style scoped>
* {
  color: white !important;
}
</style>