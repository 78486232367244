<template>
  <v-app-bar height="80" app>
    <div class="d-none d-lg-flex align-center" style="width:100%">
      <v-img
      alt="Zirconite Logo"
      class="shrink pa-0 ma-0"
      contain
      src="@/assets/LOGOZIRCONITE.webp"
      transition="scale-transition"
      height="80"
      @click="$router.push({ path: '/' }).catch(err => {})"
      style="cursor:pointer"
    />
      <v-tabs height="80" color="primary" class="ms-5">
        <v-tab link :to="'/'">Início</v-tab>
        <v-tab link :to="'/quem-somos'">Quem somos?</v-tab>
        <v-tab link :to="'/poupe-na-sua-fatura'">Poupe na sua fatura</v-tab>
        <v-tab link :to="'/trabalhe-connosco'">Trabalhe connosco</v-tab>
        <v-tab link :to="'/contactos'">Contactos</v-tab>
      </v-tabs>
    </div>
    <div class="d-flex align-center d-lg-none" style="width:100%">
      <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon tile v-bind="attrs" v-on="on">
          <v-icon style="font-size:xx-large">
            mdi-menu
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :to="'/'">
          <v-list-item-title>Início</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/quem-somos'">
          <v-list-item-title>Quem somos?</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/poupe-na-sua-fatura'">
          <v-list-item-title>Poupe na sua fatura</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/trabalhe-connosco'">
          <v-list-item-title>Trabalhe connosco</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/contactos'">
          <v-list-item-title>Contactos</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-img alt="Jaeva Logo" class="shrink pa-0 ma-0 ms-auto" contain src="@/assets/LOGOZIRCONITE.webp"
      transition="scale-transition" height="80" @click="$router.push({ path: '/' }).catch(err => { })"
      style="max-width:80px;cursor:pointer" />
    </div>
  </v-app-bar>
</template>
  
<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
    };
  },
  methods: {
    changeRoute(a) {
      this.$router.push({ path: a });
      console.log(a);
    },
  },
};
</script>


<style>
.v-tab{
  font-size: .8em !important;
  font-weight: 700 !important;
}
.v-slide-group__content {
  background-color: rgb(245,245,245) !important
}
</style>