import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AhorraView from '@/views/AhorraView.vue'
import ContactoView from '@/views/ContactoView.vue'
import TrabajaView from '@/views/TrabajaView.vue'
import InfoView from '@/views/InfoView.vue'
import SmartSolarView from '@/views/SmartSolarView.vue'
import MobilidadeView from '@/views/MobilidadeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/quem-somos',
    name: 'quem-somos',
    component: InfoView
  },
  {
    path: '/poupe-na-sua-fatura',
    name: 'poupe-na-sua-fatura',
    component: AhorraView
  },
  {
    path: '/trabalhe-connosco',
    name: 'trabalhe-connosco',
    component: TrabajaView
  },
  {
    path: '/contactos',
    name: 'contactos',
    component: ContactoView,
  },
  {
    path: '/smart-solar',
    name: 'smart-solar',
    component: SmartSolarView,
  },
  {
    path: '/mobilidade',
    name: 'Mobilidade',
    component: MobilidadeView,
  },
  {
    path: '/ig',
    beforeEnter() {window.open('https://www.instagram.com/zirconite_portugal/', '_blank');}
  },
  {
    path: '/li',
    beforeEnter() {window.open('https://www.linkedin.com/company/zirconite-portugal/', '_blank');}
  },
  {
    path: '/fb',
    beforeEnter() {window.open('https://www.facebook.com/zirconite.portugal.Iberdrola', '_blank');}
  },
  {
    path: '/asistencia',
    beforeEnter() {window.open('https://www.iberdrola.pt/contactos#lojas', '_blank');}
  },
  {
    path: '/toma-reforzada',
    beforeEnter() {window.open('https://www.iberdrola.pt/casa/mobilidade-eletrica/carregar-em-casa/tomada-reforcada', '_blank');}
  },
  {
    path: '/wallbox',
    beforeEnter() {window.open('https://www.iberdrola.pt/casa/mobilidade-eletrica/carregar-em-casa/wallbox', '_blank');}
  },
  {
    path: '*',
    name: 'catchAll',
    component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
