<template>
  <div>
    <v-breadcrumbs large :items="[
      { text: 'Zirconite', to: '/', exact: true },
      {
        text: 'Quem somos?',
        to: '/quem-somos',
        exact: true,
      },
    ]"></v-breadcrumbs>
    <v-row class="mx-auto justify-center">
      <v-col cols="12" md="8">
        <v-card style="border-top: 3px solid var(--v-primary-base)" class="text-center pt-5" elevation="6" height="100%">
          <v-card-title class="justify-center primary--text" style="word-break: break-word">
            Zirconite
          </v-card-title>
          <v-card-text>
            A Zirconite foi criada para responder a duas necessidades no setor
            energético: atendimento personalizado ao cliente e redução de
            custos. Trabalhamos como parceiro da empresa líder no setor de
            energia na Espanha: Iberdola. Na Zirconite existe uma clara vocação
            comercial e acreditamos que a qualidade das nossas operações é a
            nossa principal garantia.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto mb-12 justify-center">
      <v-col cols="12" md="4">
        <v-card style="border-top: 3px solid var(--v-primary-base)" class="text-center pt-5" elevation="6" height="100%">
          <v-icon size="35" class="primary--text">mdi-target </v-icon>
          <v-card-title class="justify-center primary--text" style="word-break: break-word">
            Missão
          </v-card-title>
          <v-card-text>
            Maximizar a satisfação dos nossos clientes, oferecendo-lhes soluções
            energéticas de acordo com as suas necessidades, minimizando os
            custos energéticos e otimizando os seus recursos. Contribuir para o
            desenvolvimento de nossos colaboradores e parceiros comerciais para
            que alcancem todo o seu potencial, oferecendo suporte e formação.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card style="border-top: 3px solid var(--v-primary-base)" class="text-center pt-5" elevation="6" height="100%">
          <v-icon size="35" class="primary--text">mdi-eye </v-icon>
          <v-card-title class="justify-center primary--text" style="word-break: break-word">
            Visão
          </v-card-title>
          <v-card-text>
            Ser uma empresa de referência no setor, ser o principal parceiro da
            Iberdrola em Portugal
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--v-col cols="12">
        <v-card class="text-center pt-5" elevation="6" height="100%">
          <v-icon size="35" class="primary--text">mdi-handshake</v-icon>
          <v-card-title
            class="justify-center primary--text"
            style="word-break: break-word"
          >
            Valores
          </v-card-title>
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <v-card-title class="justify-center">
                Transparencia
              </v-card-title>
              <v-card-text>
                Despreocúpate de tus facturas en caso de desempleo u otros
                momentos de dificultad. Contrátalo ahora y consigue dos meses
                gratis
              </v-card-text>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card-title class="justify-center"> Fiabilidad </v-card-title>
              <v-card-text>
                En Zirconite de Negocios S.L nuestros equipos comerciales
                disponen de las herramientas más avanzadas para poder explicar
                claramente a sus clientes lo que se van a ahorrar en su factura.
              </v-card-text>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card-title class="justify-center">
                Orientación al colaborador
              </v-card-title>
              <v-card-text>
                En Zirconite de Negocios S.L creemos que solo podemos crecer de
                la mano de nuestros colaboradores, por lo que les ofrecemos
                apoyo continuado y herramientas para que puedan desarrollar todo
                su potencial.
              </v-card-text>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card-title class="justify-center">
                Sostenibilidad medioambiental
              </v-card-title>
              <v-card-text>
                Optimizando el consumo energético reduciremos también el impacto
                medioambiental.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col-->
  </div>
</template>
  
<script>
export default {
  name: "InfoView",
  data() {
    return {};
  },
};
</script>