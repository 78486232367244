<template>
  <div>
    <template>
      <v-carousel class="carouselSombra" cycle hide-delimiters :show-arrows="false" dark height="450">
        <v-carousel-item reverse-transition="fade-transition" transition="fade-transition">
          <v-sheet height="100%" tile style="
              background-image: url('/img/1.jpg');
              background-size: cover;
              background-position: center bottom;
            ">
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item reverse-transition="fade-transition" transition="fade-transition">
          <v-sheet height="100%" tile style="
              background-image: url('/img/2.jpg');
              background-size: cover;
              background-position: center bottom;
            ">
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item reverse-transition="fade-transition" transition="fade-transition">
          <v-sheet height="100%" tile style="
              background-image: url('/img/3.jpg');
              background-size: cover;
              background-position: center bottom;
            ">
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </template>
    <div style="
        background-image: url('/img/zirconite.webp');
        background-size: contain;
        background-position: center;
      ">
      <v-row class="d-flex flex-row">
        <v-col cols="12" md="10" offset-md="1">
          <v-row class="justify-center mt-10">
            <v-col cols="12" md="4" sm="7" xs="10" class="px-10">
              <v-card class="text-center hoverable" style="cursor: pointer" elevation="6" height="100%"
                to="/smart-solar">
                <v-img class="gradient" src="@/assets/smart_solar.webp" style="max-width: 100%"></v-img>
                <v-card-title class="justify-center primary--text" style="word-break: break-word">
                  Smart solar
                </v-card-title>
                <v-card-text class="black--text">
                  <h2>Painéis Solares</h2>
                  <h3>Energia Solar para a sua casa</h3>
                  <strong style="color: var(--v-primary-base)">A partir de 24,90€ / 36 meses</strong><br />
                  A nossa solução de energia solar permite-lhe gerar e consumir
                  a sua própria energia de forma sustentável e eficiente, sem
                  juros e sem investimento inicial.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="7" xs="10" class="px-10">
              <v-card class="text-center hoverable" style="cursor: pointer" elevation="6" height="100%"
              to="/mobilidade">
                <v-img class="gradient" src="@/assets/mobilidade.webp" style="max-width: 100%"></v-img>
                <v-card-title class="justify-center primary--text" style="word-break: break-word">
                  Mobilidade
                </v-card-title>
                <v-card-text class="black--text">
                  Com as soluções de Mobilidade Elétrica Iberdrola, carregar o
                  seu veículo elétrico em casa é fácil, cómodo e seguro. Escolha
                  a solução que melhor se adapta às suas necessidades e acelere
                  em direção ao futuro.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="7" xs="10" class="px-10">
              <v-card class="text-center hoverable" style="cursor: pointer" elevation="6" height="100%"
                href='https://www.omie.es/pt'>
                <v-img class="gradient" src="@/assets/omi.webp" style="max-width: 100%"></v-img>
                <v-card-title class="primary--text justify-center" style="word-break: break-word">
                  OMIE
                </v-card-title>
                <v-card-text class="black--text">
                  O OMIE é o operador de mercado elétrico designado para a
                  gestão do mercado diário e intradiário de eletricidade na
                  Península Ibérica.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
<style>
* {
  transition-duration: 250ms !important;
  transition-property: transform !important;
}

.hoverable:hover {
  z-index: 999 !important;
  transform: scale(1.1);
}

.carouselSombra {
  box-shadow: 0px 0px 25px 0px #000000;
}

.gradient {
  mask-image: linear-gradient(to top,
      rgba(30, 87, 153, 0) 0%,
      rgba(255, 255, 255, 0.6) 15%,
      rgba(255, 255, 255, 1) 100%);
}
</style>