<template>
  <div>
    <v-breadcrumbs large :items="[
      { text: 'Zirconite', to: '/', exact: true },
      { text: 'Mobilidade', to: '/mobilidade', exact: true },
    ]"></v-breadcrumbs>
    <v-sheet
      class="banner contenido d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start"
      height="50vh">
      <v-row class="ms-sm-16 align-center">
        <v-col cols="12" lg="4" md="5" sm="8" class="text-center text-sm-left">
          <v-icon size="80" color="primary">mdi-ev-station</v-icon>
          <h1 class="mb-5">Carregue o seu carro elétrico em casa.</h1>
          <p class="mx-15 mx-sm-0">Com as soluções de Mobilidade Elétrica Iberdrola, carregar o seu veículo elétrico em
            casa é fácil, cómodo e seguro. Escolha a solução que melhor se adapta às suas necessidades e acelere em
            direção ao futuro.</p>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="text-center mt-16">
      <v-row class="justify-center">
        <v-col cols="12" md="3" sm="5">
          <h2 class="mb-3">Mobilidade Elétrica</h2>
          <p class="text--secondary">Conheça as vantagens de carregar o seu veículo elétrico em casa.</p>
        </v-col>
      </v-row>
      <div class="d-block d-lg-none">
        <template>
          <v-carousel hide-delimiter-background light height="428px">
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_5.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <p>Cómodo</p>
                    <h3 class="mb-5"> Descomplique os seus carregamentos </h3>
                    <p> Com as soluções de carregamento Iberdrola carregue o seu veículo no conforto da sua casa no
                      horário mais conveniente. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px" class="flip-card"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_6.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <p>Económico</p>
                    <h3 class="mb-5"> Investimento a longo prazo </h3>
                    <p> Permita ao seu veículo elétrico recarregar energias, enquanto poupa na fatura. Usufrua de
                      carregamentos que respeitam e preservam a vida da bateria do seu veículo e aproveite planos de
                      eletricidade mais vantajosos. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px" class="flip-card"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_7.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <p>Instalação</p>
                    <h3 class="mb-5"> Rápida e sem imprevistos </h3>
                    <p> Asseguramos a instalação do carregador mais adequado às suas necessidades. Receba a nossa equipa
                      de especialistas em sua casa para uma instalação sem surpresas. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </template>
      </div>
      <div class="d-none d-lg-block">
        <v-row class="justify-center mx-auto" style="width: 90%;">
          <v-col cols="4">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:url('img/card_8.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Oferta à medida e facilidade de pagamento
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Oferta à medida e facilidade de pagamento
                  </strong>
                  <p class="texto-card mt-5">
                    Faça uma simulação connosco e descubra qual a solução ideal para si. Pode pagar a sua solução
                    Smart Solar em 36 meses sem juros e sem investimento inicial, ou de uma só vez.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:url('img/card_9.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Visita Prévia Incluída
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Visita Prévia Incluída
                  </strong>
                  <p class="texto-card mt-5">
                    Aproveite a visita de um técnico comercial à sua casa para avaliar a viabilidade técnica, antes de
                    instalar os painéis, sem custos adicionais.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:url('img/card_10.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Instalação e Garantia
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Instalação e Garantia
                  </strong>
                  <p class="texto-card mt-5">
                    Os seus novos equipamentos e a instalação, tal como a sua garantia, estão incluídos no preço final.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
    <h2 class="text-center my-16">Escolha a solução que melhor se adapta às suas necessidades</h2>
    <v-row class="justify-center">
      <table class="d-none d-md-table"
        style="table-layout: fixed;border-collapse: collapse;border-radius: 18px;box-shadow: 0px 9px 22px rgb(0 0 0 / 10%);width: 1117px;">
        <thead>
          <tr>
            <th style="background: #f9f9f9;"></th>
            <th style="border-right:1px solid #e7e7e7;">
              <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;" class="justify-center">
                Monofásico
              </div>
              <div>
                <img src="img/reforzada.png" style="vertical-align: middle; border-style: none;" width="80px"
                  height="83px">
                <h3 style="font-weight:bold">Tomada Reforçada</h3>
                <h3 class="font-weight-bold" style="color:var(--v-primary-base);min-height: 25px;"></h3>
              </div>
            </th>
            <th>
              <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;" class="justify-center">
                <v-select outlined class="mx-5 mt-10" :items="base" :item-value="item => item"
                  :item-text="item => item.tipo" v-model="seleccionadoBase"></v-select>
              </div>
              <div>
                <img src="img/reforzada.png" style="vertical-align: middle; border-style: none;" width="80px"
                  height="83px">
                <h3 style="font-weight:bold">Wallbox Pulsar</h3>
                <h3 class="font-weight-bold" style="color:var(--v-primary-base);min-height: 25px;">Pack Base</h3>
              </div>
            </th>
            <th>
              <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;" class="justify-center">
                <v-select outlined class="mx-5 mt-10" :items="premium" :item-value="item => item"
                  :item-text="item => item.tipo" v-model="seleccionadoPremium"></v-select>
              </div>
              <div>
                <img src="img/reforzada.png" style="vertical-align: middle; border-style: none;" width="80px"
                  height="83px">
                <h3 style="font-weight:bold">Wallbox Pulsar Plus</h3>
                <h3 class="font-weight-bold" style="color:var(--v-primary-base);min-height: 25px;">Pack Premium</h3>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Gestão através do
              telemóvel</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">-</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Local</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Local e remota
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Conectividade</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">-</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Bluetooth</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Bluetooth e Wi-Fi
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Gestor de cargas
              dinâmico</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">-</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">-</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
              <v-icon>mdi-check</v-icon>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Potência máxima
            </td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">3,7kW</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoBase.potencia
            }}</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoPremium.potencia
            }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Conetores
              compatíveis</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">Tipo 1 e 2</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoBase.conectores
            }}</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoPremium.conectores
            }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Instalação</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">
              <ul class="text-left">
                <li>Instalação kit (tomada, suporte e disjuntor)</li>
                <li>Distância ao quadro elétrico até 10 m</li>
              </ul>
            </td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
              <ul class="text-left">
                <li>Instalação Wallbox Pulsar</li>
                <li>Distância ao quadro elétrico até 10 m</li>
                <li>Dispositivo de proteção diferencial</li>
              </ul>
            </td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
              <ul class="text-left">
                <li>Instalação Wallbox Pulsar Plus</li>
                <li>Instalação Gestor de Cargas Dinâmico</li>
                <li>Distância ao quadro elétrico até 20 m</li>
                <li>Calha técnica 10 m</li>
                <li>Dispositivo de proteção diferencial e termomagnética</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Ideal para</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">Híbridos e motas / bicicletas elétricas</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Hibridos plug-in
              e veículos 100% elétricos</td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Hibridos plug-in
              e veículos 100% elétricos</td>
          </tr>
          <tr>
            <td class="font-weight-bold ps-5 pb-2 pe-5" style="background: #f9f9f9;font-size: 18px;">Preço (IVA
              incluído)</td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">
              <p class="font-weight-bold mb-0">288€</p>
              <p>(8 € / mês)</p>
            </td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
              <p class="font-weight-bold mb-0">{{ seleccionadoBase.precioTotal }}</p>
              <p>{{ seleccionadoBase.precioMensual }}</p>
            </td>
            <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
              <p class="font-weight-bold mb-0">{{ seleccionadoPremium.precioTotal }}</p>
              <p>{{ seleccionadoPremium.precioMensual }}</p>
            </td>
          </tr>
          <tr>
            <td style="background: #f9f9f9;font-size: 18px;"></td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center">
              <v-btn to="/toma-reforzada" style="width:80%" class="py-5" rounded outlined color="primary">VER MAIS
              </v-btn>
            </td>
            <td style="border-right:1px solid #e7e7e7;text-align: center;vertical-align: top;padding: 15px 40px;"
              class="text-center" colspan="2">
              <v-btn to="/wallbox" style="width:80%" class="py-5" rounded outlined color="primary">VER PACKS</v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <v-carousel height="auto" hide-delimiters class="d-md-none justify-center" v-model="model">
        <v-carousel-item eager>
          <table class="mx-auto my-5"
            style="max-width:300px;table-layout: fixed;border-collapse: collapse;border-radius: 18px;box-shadow: 0px 9px 22px rgb(0 0 0 / 10%);">
            <thead>
              <tr>
                <th>
                  <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;"
                    class="pt-5 justify-center text-h4 font-weight-bold">
                    Monofásico
                  </div>
                  <div>
                    <img src="img/reforzada.png" style="vertical-align: middle; border-style: none;" width="80px"
                      height="83px">
                    <h3 style="font-weight:bold">Tomada Reforçada</h3>
                    <h3 class="font-weight-bold mb-16" style="color:var(--v-primary-base);min-height: 25px;"></h3>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold"> Gestão através do telemóvel </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">-</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Conectividade</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">-</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Gestor de cargas dinâmico</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">-</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Potência máxima</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">3,7kW</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Conetores compatíveis</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Tipo 1 e 2
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Instalação</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <ul class="text-left">
                    <li>Instalação kit (tomada, suporte e disjuntor)</li>
                    <li>Distância ao quadro elétrico até 10 m</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Ideal para</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Híbridos e
                  motas / bicicletas elétricas</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Preço (IVA incluído)</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <p class="font-weight-bold mb-0">288€</p>
                  <p>(8 € / mês)</p>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <v-btn to="/toma-reforzada" style="width:80%" class="py-5" rounded outlined color="primary">VER MAIS
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-carousel-item>
        <v-carousel-item eager>
          <table class="mx-auto my-5"
            style="max-width:300px;table-layout: fixed;border-collapse: collapse;border-radius: 18px;box-shadow: 0px 9px 22px rgb(0 0 0 / 10%);">
            <thead>
              <tr>
                <th>
                  <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;" class="justify-center">
                    <v-select outlined class="mx-5 mt-10 pt-5" :items="base" :item-value="item => item"
                      :item-text="item => item.tipo" v-model="seleccionadoBase"></v-select>
                  </div>
                  <div>
                    <img src="img/reforzada.png" style="vertical-align: middle; border-style: none;" width="80px"
                      height="83px">
                    <h3 style="font-weight:bold">Wallbox Pulsar</h3>
                    <h3 class="font-weight-bold mb-16" style="color:var(--v-primary-base);min-height: 25px;">Pack Base</h3>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold"> Gestão através do telemóvel </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Local</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Conectividade</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Bluetooth</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Gestor de cargas dinâmico</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">-</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Potência máxima</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoBase.potencia
            }}</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Conetores compatíveis</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoBase.conectores
            }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Instalação</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <ul class="text-left">
                    <li>Instalação Wallbox Pulsar</li>
                    <li>Distância ao quadro elétrico até 10 m</li>
                    <li>Dispositivo de proteção diferencial</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Ideal para</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Hibridos plug-in e veículos 100% elétricos</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Preço (IVA incluído)</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <p class="font-weight-bold mb-0">{{ seleccionadoBase.precioTotal }}</p>
                  <p>{{ seleccionadoBase.precioMensual }}</p>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <v-btn to="/toma-reforzada" style="width:80%" class="py-5" rounded outlined color="primary">VER PACKS
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-carousel-item>
        <v-carousel-item eager>
          <table class="mx-auto my-5"
            style="max-width:300px;table-layout: fixed;border-collapse: collapse;border-radius: 18px;box-shadow: 0px 9px 22px rgb(0 0 0 / 10%);">
            <thead>
              <tr>
                <th>
                  <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;" class="justify-center">
                    <v-select outlined class="mx-5 mt-10 pt-5" :items="base" :item-value="item => item"
                      :item-text="item => item.tipo" v-model="seleccionadoBase"></v-select>
                  </div>
                  <div>
                    <img src="img/reforzada.png" style="vertical-align: middle; border-style: none;" width="80px"
                      height="83px">
                    <h3 style="font-weight:bold">Wallbox Pulsar</h3>
                    <h3 class="font-weight-bold mb-16" style="color:var(--v-primary-base);min-height: 25px;">Pack Premium</h3>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold"> Gestão através do telemóvel </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Local e remota</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Conectividade</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Bluetooth e Wi-Fi</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Gestor de cargas dinâmico</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center"><v-icon color="primary">mdi-check</v-icon></td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Potência máxima</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoPremium.potencia
            }}</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Conetores compatíveis</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">{{
                seleccionadoPremium.conectores
            }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Instalação</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <ul class="text-left">
                    <li>Instalação Wallbox Pulsar Plus</li>
                    <li>Instalação Gestor de Cargas Dinâmico</li>
                    <li>Distância ao quadro elétrico até 20 m</li>
                    <li>Calha técnica 10 m</li>
                    <li>Dispositivo de proteção diferencial e termomagnética</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Ideal para</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">Hibridos plug-in e veículos 100% elétricos</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;"
                  class="text-center font-weight-bold">Preço (IVA incluído)</td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <p class="font-weight-bold mb-0">{{ seleccionadoPremium.precioTotal }}</p>
                  <p>{{ seleccionadoPremium.precioMensual }}</p>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align: top;padding: 15px 40px;" class="text-center">
                  <v-btn to="/wallbox" style="width:80%" class="py-5" rounded outlined color="primary">VER PACKS
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-carousel-item>
      </v-carousel>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "MobilidadeView",
  data() {
    return {
      model: 0,
      e1: 1,
      base: [
        {
          tipo: "Monofásico",
          conectores: "Tipo 1 e 2",
          potencia: '7,4kW',
          precioTotal: "850,00€",
          precioMensual: "(23,61€ / mês)",
        },
        {
          tipo: "Trifásico",
          conectores: "Tipo 2",
          potencia: '22kW',
          precioTotal: "1.050,00€",
          precioMensual: "(29,17€ / mês)",
        },
      ],
      seleccionadoBase: {
        tipo: "Monofásico",
        conectores: "Tipo 1 e 2",
        potencia: '7,4kW',
        precioTotal: "850,00€",
        precioMensual: "(23,61€ / mês)",
      },
      premium: [
        {
          tipo: "Monofásico",
          conectores: "Tipo 1 e 2",
          potencia: '7,4kW',
          precioTotal: "1.620,00€",
          precioMensual: "(45,00€ / mês)",
        },
        {
          tipo: "Trifásico",
          conectores: "Tipo 2",
          potencia: '22kW',
          precioTotal: "2.160,00€",
          precioMensual: "(60,00€ / mês)",
        },
      ],
      seleccionadoPremium: {
        tipo: "Monofásico",
        conectores: "Tipo 1 e 2",
        potencia: '7,4kW',
        precioTotal: "1.620,00€",
        precioMensual: "(45,00€ / mês)",
      },
    };
  },
};
</script>
<style scoped>
.banner {
  background: url("@/assets/mobilidade_banner.webp");
  background-size: cover;
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: var(--v-primary-base);
  font-weight: 900;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.titulo-card {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
}

.theme--dark.v-btn.v-btn--icon {
  color: #000000 !important;
}

.v-btn .v-btn__content .v-icon {
  color: black;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  text-align: left;
  padding: 32px 16px;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: white;
}

.flip-card-back {
  background-color: #ffffff;
  color: black;
  transform: rotateY(180deg);
}
</style>