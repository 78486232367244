<template>
  <div>
    <v-breadcrumbs large :items="[
      { text: 'Zirconite', to: '/', exact: true },
      { text: 'Smart solar', to: '/smart-solar', exact: true },
    ]"></v-breadcrumbs>
    <v-sheet class="justify-center align-center py-2" style="background-color:var(--v-primary-base)">
      <div class="white--text text-h5 mx-auto text-center">
        <v-icon size="40" class="me-5" color="white">mdi-sale-outline</v-icon>Usufrua da redução do IVA de 23% para 6%.
      </div>
    </v-sheet>
    <v-sheet
      class="banner contenido d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start"
      height="50vh">
      <v-row class="ms-sm-16 align-center">
        <v-col cols="12" lg="4" md="5" sm="8" class="text-center text-sm-left">
          <v-icon size="80" color="primary">mdi-solar-panel</v-icon>
          <h1>Painéis Solares</h1>
          <h2>Energia Solar para a sua casa</h2>
          <strong style="color:var(--v-primary-base)">A partir de 24,90€ / 36 meses</strong>
          <p class="mx-15 mx-sm-0">A nossa solução de energia solar permite-lhe gerar e consumir a sua própria energia
            de forma sustentável
            e eficiente, sem juros e sem investimento inicial.</p>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="text-center mt-16">
      <v-row class="justify-center">
        <v-col cols="12" md="3" sm="5">
          <h2 class="mb-3">A sua energia tem vantagens</h2>
          <p class="text--secondary">Disponibilizamos-lhe uma forma de gerar energia, cómoda e autónoma,
            adaptada às suas necessidades de consumo, que lhe permite poupar na fatura de eletricidade.</p>
        </v-col>
      </v-row>
      <div class="d-block d-lg-none">
        <template>
          <v-carousel hide-delimiter-background light height="428px">
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_1.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <h3 class="mb-5"> Oferta à medida e facilidade de pagamento </h3>
                    <p> Faça uma simulação connosco e descubra qual a solução ideal para si. Pode pagar a sua solução
                      Smart Solar em 36 meses sem juros e sem investimento inicial, ou de uma só vez. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_2.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <h3 class="mb-5"> Visita Prévia Incluída </h3>
                    <p> Aproveite a visita de um técnico comercial à sua casa para avaliar a viabilidade técnica, antes
                      de instalar os painéis, sem custos adicionais. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px" class="flip-card"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_3.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <h3 class="mb-5"> Instalação e Garantia </h3>
                    <p> Os seus novos equipamentos e a instalação, tal como a sua garantia, estão incluídos no preço
                      final. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="mx-auto">
              <v-card height="378px" width="266px" class="flip-card"
                style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_4.webp');background-color: transparent;perspective: 1000px;">
                <div class="text-left"
                  style="position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.8s;transform-style: preserve-3d;">
                  <div class="py-8 px-6" height="100%" style="background-size:cover">
                    <h3 class="mb-5"> Sistema de Monitorização </h3>
                    <p> Acompanhe na nossa app o seu consumo e receba notificações personalizadas que o ajudam a poupar
                      na sua fatura. </p>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </template>
      </div>
      <div class="d-none d-lg-block">
        <v-row class="justify-center mx-auto" style="width: 90%;">
          <v-col cols="3">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_1.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Oferta à medida e facilidade de pagamento
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Oferta à medida e facilidade de pagamento
                  </strong>
                  <p class="texto-card mt-5">
                    Faça uma simulação connosco e descubra qual a solução ideal para si. Pode pagar a sua solução
                    Smart Solar em 36 meses sem juros e sem investimento inicial, ou de uma só vez.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_2.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Visita Prévia Incluída
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Visita Prévia Incluída
                  </strong>
                  <p class="texto-card mt-5">
                    Aproveite a visita de um técnico comercial à sua casa para avaliar a viabilidade técnica, antes de
                    instalar os painéis, sem custos adicionais.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_3.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Instalação e Garantia
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Instalação e Garantia
                  </strong>
                  <p class="texto-card mt-5">
                    Os seus novos equipamentos e a instalação, tal como a sua garantia, estão incluídos no preço final.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div height="320px" width="312px" class="mx-auto flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front"
                  style="color:white;background-size:cover;background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('img/card_4.webp');background-color: transparent;perspective: 1000px;">
                  <strong class="titulo-card">
                    Sistema de Monitorização
                  </strong>
                </div>
                <div class="flip-card-back">
                  <strong class="titulo-card">
                    Sistema de Monitorização
                  </strong>
                  <p class="texto-card mt-5">
                    Acompanhe na nossa app o seu consumo e receba notificações personalizadas que o ajudam a poupar na
                    sua fatura.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
    <div class="justify-center mt-16 mx-16">
      <h2 style="font-size: 32px;line-height: 36px;" class="text-center mb-10">Que solução oferecemos ?</h2>
      <template>
        <v-stepper v-model="e1" max-width="1200px" class="mx-auto">
          <v-stepper-header>
            <v-stepper-step step="1" style="cursor:pointer" @click="e1=1">
              Painéis solares
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" style="cursor:pointer" @click="e1=2">
              Consumo próprio de eletricidade
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" style="cursor:pointer" @click="e1=3">
              Excesso de energia
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" style="cursor:pointer" @click="e1=4">
              Consumo da rede elétrica
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-sheet class="mb-12">
                <v-row class="justify-center align-center ms-0 ms-md-10">
                  <v-col cols="12" md="6"
                    class="justify-center align-center d-flex justify-md-start align-md-start d-md-block">
                    <img src="/img/stepper_1.svg">
                  </v-col>
                  <v-col cols="12" md="6" style="width: 226px;" class="text-center text-md-left">
                    <strong style="font-size: 24px;line-height: 26px;margin-bottom: 16px;width: 226px;">Painéis
                      solares</strong>
                    <p style="width: 226px;font-size: 14px;" class="mb-1 mx-auto mx-md-0"> Os sistemas fotovoltaicos
                      para autoconsumo são geralmente compostos por:
                      painel solar fotovoltaico, microinversor ou inversor, estrutura e consumíveis. Muitas vezes também
                      se verifica
                      a existência de um sistema de monitorização e/ou de uma bateria para armazenamento da energia.
                    </p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-sheet class="mb-12">
                <v-row class="justify-center align-center ms-0 ms-md-10">
                  <v-col cols="12" md="6"
                    class="justify-center align-center d-flex justify-md-end align-md-end d-md-block">
                    <img class="ms-0 ms-md-16" src="/img/stepper_2.svg">
                  </v-col>
                  <v-col cols="12" md="6" style="width: 226px;" class="text-center text-md-left">
                    <strong style="font-size: 24px;line-height: 26px;margin-bottom: 16px;width: 226px;">Consumo próprio
                      de eletricidade</strong>
                    <p style="width: 226px;font-size: 14px;" class="mb-1 mx-auto mx-md-0"> O painel solar capta a
                      energia solar e o microinversor converte-a em
                      energia elétrica. Este equipamento elétrico tem como função converter a corrente contínua em
                      corrente alternada, de forma a que a energia produzida possa ser consumida em casa.
                    </p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-sheet class="mb-12">
                <v-row class="justify-center align-center ms-0 ms-md-10">
                  <v-col cols="12" md="6"
                    class="justify-center align-center d-flex justify-md-start align-md-start d-md-block">
                    <img src="/img/stepper_3.svg">
                  </v-col>
                  <v-col cols="12" md="6" style="width: 226px;"
                    class="text-center text-md-left justify-center align-center">
                    <strong style="font-size: 24px;line-height: 26px;margin-bottom: 16px;width: 226px;"> Excedente de
                      energia</strong>
                    <p style="width: 272px;font-size: 14px;" class="mb-1 mx-auto mx-md-0"> Os sistemas fotovoltaicos
                      para autoconsumo são geralmente compostos por:
                      Na maioria dos casos, o painel solar irá produzir mais energia do que a que está a ser consumida
                      em casa no momento.
                    </p>
                    <p style="width: 272px;font-size: 14px;" class="mb-1 mx-auto mx-md-0"> Quando isto acontece, este
                      excedente de energia é novamente injetado na
                      rede elétrica pública, ou vendido a um comercializador de energia.
                    </p>
                    <p style="width: 272px;font-size: 14px;" class="mb-1 mx-auto mx-md-0"> Uma alternativa para
                      aproveitar este excedente de energia é a bateria, que
                      armazena a energia excedentária para a poder consumir durante a noite, por exemplo.
                    </p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-sheet class="mb-12">
                <v-row class="justify-center align-center ms-0 ms-md-10">
                  <v-col cols="12" md="6"
                    class="justify-center align-center d-flex justify-md-start align-md-start d-md-block">
                    <img src="/img/stepper_4.svg">
                  </v-col>
                  <v-col cols="12" md="6" style="width: 226px;" class="text-center text-md-left">
                    <strong style="font-size: 24px;line-height: 26px;margin-bottom: 16px;width: 226px;">Consumo da rede
                      elétrica</strong>
                    <p style="width: 226px;font-size: 14px;" class="mb-1 mx-auto mx-md-0"> Quando o consumo de energia
                      em casa é superior àquela que é produzida
                      pelos painéis, a energia que consome em casa vem diretamente da rede pública. Isto acontece por
                      exemplo durante a noite, quando não existe exposição solar.
                    </p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </div>
    <div class="text-center mt-16">
      <h2 class="text-center mt-16" style="color:var(--v-primary-base)">Escolha o pacote que pretende contratar</h2>
      <v-select class="mx-auto px-16" style="max-width: 900px" :items="items"
        :item-text="item => item.nPaneles + panelTexto(item.nPaneles) +' - '+ item.potencia + 'Wp'"
        :item-value="item => item" outlined v-model="seleccionado">
      </v-select>
    </div>
    <v-row style="max-width:900px" class="mx-auto">
      <v-col cols="12" md="6">
        <h3 style="font-size: 40px;" class="text-center text-md-left">{{ seleccionado.nPaneles +
        panelTexto(seleccionado.nPaneles) }}</h3>
        <p style="font-size: 18px;color:var(--v-primary-base)" class="text-center text-md-left">{{ seleccionado.potencia
        + "Wp" }}</p>
        <h3 class="text-center text-md-left">Os Pacotes Smart Solar incluem:</h3>
        <v-row class="px-16 mx-auto mx-md-0 px-md-0">
          <v-col cols="12">
            <v-row>
              <v-col cols="2">
                <img style="width: 32px;height: 32px;margin: 16px;border-radius: 16px 16px 0 0;"
                  src="img/icoPanel_1.svg">
              </v-col>
              <v-col cols="10" class="d-flex align-center">
                <h4 style="color: #9B9B9B;">Painéis 340Wp</h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row style="background: #F9F9F9;">
              <v-col cols="2">
                <img style="width: 32px;height: 32px;margin: 16px;" src="img/icoPanel_2.svg">
              </v-col>
              <v-col cols="10" class="d-flex align-center">
                <h4 style="color: #9B9B9B;">Inversor ou microinversor</h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row style="">
              <v-col cols="2">
                <img style="width: 32px;height: 32px;margin: 16px;" src="img/icoPanel_3.svg">
              </v-col>
              <v-col cols="10" class="d-flex align-center">
                <h4 style="color: #9B9B9B;">Instalação e configuração</h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row style="background: #F9F9F9;border-radius: 0 0 16px 16px;">
              <v-col cols="2">
                <img style="width: 32px;height: 32px;margin: 16px;" src="img/icoPanel_4.svg">
              </v-col>
              <v-col cols="10" class="d-flex align-center">
                <h4 style="color: #9B9B9B;">Sistema de Monitorização</h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="color:var(--v-primary-base)" class="mt-10 justify-center justify-md-start">
          <strong class="pa-3">Usufrua da redução do IVA de 23% para 6%</strong>
        </v-row>
        <v-card class="ps-7 py-4 pe-4 mt-5 justify-center align-center mx-auto mx-md-0" width="266">
          <p style="font-size: 32px;font-weight: 700;line-height: 36px;text-align: left;color: black;margin-bottom: 13px;"
            v-if="iva">{{ seleccionado.precioTotal[1].precio }}€</p>
          <p style="font-size: 32px;font-weight: 700;line-height: 36px;text-align: left;color: black;margin-bottom: 13px;"
            v-else>{{ seleccionado.precioTotal[0].precio }}€</p>
          <v-row class="justify-space-between align-center ma-0">
            <p style="font-size: 14px;font-weight: 400;line-height: 22px;text-align: left;color: #707070;margin-bottom: 0;"
              v-if="iva">{{ seleccionado.precioMensual[1].precio}}€ x 36 meses</p>
            <p style="font-size: 14px;font-weight: 400;line-height: 22px;text-align: left;color: #707070;margin-bottom: 0;"
              v-else>{{ seleccionado.precioMensual[0].precio}}€ x 36 meses</p>
            <div class="d-flex flex-row align-center">
              <p class="me-4 mb-0">IVA</p>
              <v-switch class="mt-0 align-center" v-model="iva" hide-details="true"></v-switch>
            </div>
          </v-row>
          <div class="mt-8" :class="iva ? '' : 'd-none'">
            <p
              style="font-size: 32px;font-weight: 700;line-height: 36px;text-align: left;text-decoration-line: line-through;color: #B9B9B9;margin-bottom: 13px;">
              {{ seleccionado.precioReducido[0].precioTotal }}</p>
            <v-row class="justify-space-between align-center ma-0">
              <p
                style="font-size: 14px;font-weight: 400;line-height: 22px;text-align: left;text-decoration-line: line-through;color: #B9B9B9;margin-bottom: 0;">
                {{ seleccionado.precioReducido[1].precioMensual }}</p>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6" class="d-none d-md-block">
        <img src="img/panel.png">
      </v-col>
    </v-row>
    <v-row style="background-color:#f9f9f9">
      <v-col cols="12" class="mt-9 justify-center align-center d-flex flex-column">
        <h2 class="ma-4" style="text-align: center;font-size: 32px;line-height: 40px;">Garantia Iberdrola</h2>
        <p style="width: 468px;text-align: center;">Acreditamos na qualidade dos nossos produtos mas para que nunca lhe
          falte nada oferecemos as seguintes garantias:</p>
      </v-col>
      <v-row style="max-width:1200px" class="mx-auto pb-16">
        <v-col cols="12" sm="6" md="3">
          <div width="270px"
            style="box-shadow: 0px 5px 12px rgb(0 0 0 / 12%); height: 84px; border-radius: 16px; background: #ffffff; margin-right: 10px;"
            height="84" class="d-flex align-center">
            <img class="ms-5" src="img/garantia_1.svg">
            <div class="me-3 me-sm-0">
              <h3 class="mb-0 text-center"
                style="margin-left: 20px; font-weight: 400; font-size: 14px; line-height: 24px; color:var(--v-primary-base)">
                Garantia de
                instalação</h3>
              <p class="mb-0" style="margin-left: 20px;font-weight:bold">3 anos</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div width="270px"
            style="box-shadow: 0px 5px 12px rgb(0 0 0 / 12%); height: 84px; border-radius: 16px; background: #ffffff; margin-right: 10px;"
            height="84" class="d-flex align-center">
            <img class="ms-5" src="img/garantia_1.svg">
            <div class="me-3 me-sm-0">
              <h3 class="mb-0 text-center"
                style="margin-left: 20px; font-weight: 400; font-size: 14px; line-height: 24px; color:var(--v-primary-base)">
                arantia dos painéis</h3>
              <p class="mb-0" style="margin-left: 20px;font-weight:bold">12 anos</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div width="270px"
            style="box-shadow: 0px 5px 12px rgb(0 0 0 / 12%); height: 84px; border-radius: 16px; background: #ffffff; margin-right: 10px;"
            height="84" class="d-flex align-center">
            <img class="ms-5" src="img/garantia_1.svg">
            <div class="me-3 me-sm-0">
              <h3 class="mb-0 text-left text-lg-center"
                style="margin-left: 20px; font-weight: 400; font-size: 14px; line-height: 24px; color:var(--v-primary-base)">
                Garantia dos Microinversores</h3>
              <p class="mb-0" style="margin-left: 20px;font-weight:bold">5 anos</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div width="270px"
            style="box-shadow: 0px 5px 12px rgb(0 0 0 / 12%); height: 84px; border-radius: 16px; background: #ffffff; margin-right: 10px;"
            height="84" class="d-flex align-center">
            <img class="ms-5" src="img/garantia_1.svg">
            <div class="me-3 me-sm-0">
              <h3 class="mb-0 text-center"
                style="margin-left: 20px; font-weight: 400; font-size: 14px; line-height: 24px; color:var(--v-primary-base)">
                Vida Útil</h3>
              <p class="mb-0" style="margin-left: 20px;font-weight:bold">25 anos</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="mt-16">
      <v-col cols="12" md="6">
        <div class="mx-auto" style="width:64px;height:64px">
          <img src="img/telefono.svg">
        </div>
        <p style="font-size: 24px;font-weight: bold;line-height: 1.25;text-align: center;color: #2c2c2c;">
          Pode contratar por chamada
        </p>
        <p style="font-size: 18px;font-weight: bold;line-height: 1.25;text-align: center;color: #2C2C2C;">Ligue grátis</p>
        <p style="font-size: 32px;font-weight: bold;line-height: 0.94;text-align: center;color:var(--v-primary-base)">800 607 706</p>
        <p style="max-width: 600px;margin: 0 auto;margin-top: 20px;font-size: 14px;line-height: 1.38;text-align: center;color: #707070;width: 70%;">Dias úteis, das 9h às 20h.</p>
      </v-col>
      <v-divider class="d-none d-md-block" inset vertical></v-divider>
      <v-col cols="12" md="6">
        <div class="mx-auto" style="width:64px;height:64px">
          <img src="img/oficina.svg">
        </div>
        <p style="font-size: 24px;font-weight: bold;line-height: 1.25;text-align: center;color: #2c2c2c;">
          Pode contratar nas nossas lojas
        </p>
        <p class="mx-auto" style="width: 240px;font-size: 18px;font-weight: bold;line-height: 1.25;text-align: center;color: #2C2C2C;">Encontre o ponto de apoio ao cliente mais próximo de si.</p>
        <router-link to="/asistencia">
          <v-btn style="color:white;width: 250px;height: 55px;font-size: 16px;font-weight: 700;line-height: 24px;display: flex;justify-content: center;flex-direction: column;margin: 20px auto;background-color: var(--v-primary-base);border: solid 1px var(--v-primary-base);border-radius: 31px;text-align: center;">
            Ver mapa
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ContactoView",
  data() {
    return {
      e1: 1,
      items: [{
        nPaneles: 1,
        potencia: '340',
        precioTotal: [
          { iva: false, precio: "845,66" },
          { iva: true, precio: "896,40" },
        ],
        precioMensual: [
          { iva: false, precio: "23,49" },
          { iva: true, precio: "24,90" },
        ],
        precioReducido: [
          { precioTotal: "1.040,00€" },
          { precioMensual: "29,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 2,
        potencia: '680',
        precioTotal: [
          { iva: false, precio: "1.389,06" },
          { iva: true, precio: "1.472,40" },
        ],
        precioMensual: [
          { iva: false, precio: "38,58" },
          { iva: true, precio: "40,90" },
        ],
        precioReducido: [
          { precioTotal: "1.709,00€" },
          { precioMensual: "47,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 3,
        potencia: '1.020',
        precioTotal: [
          { iva: false, precio: "1.762,64" },
          { iva: true, precio: "1.868,40" },
        ],
        precioMensual: [
          { iva: false, precio: "48,96" },
          { iva: true, precio: "51,90" },
        ],
        precioReducido: [
          { precioTotal: "2.168,00€" },
          { precioMensual: "60,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 4,
        potencia: '1.360',
        precioTotal: [
          { iva: false, precio: "2.170,19" },
          { iva: true, precio: "2.300,40" },
        ],
        precioMensual: [
          { iva: false, precio: "60,28" },
          { iva: true, precio: "63,90" },
        ],
        precioReducido: [
          { precioTotal: "2.669,00€" },
          { precioMensual: "74,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 5,
        potencia: '1.700',
        precioTotal: [
          { iva: false, precio: "2.577,74" },
          { iva: true, precio: "2.732,40" }
        ],
        precioMensual: [
          { iva: false, precio: "71,60" },
          { iva: true, precio: "75,90" },
        ],
        precioReducido: [
          { precioTotal: "3.171,00€" },
          { precioMensual: "88,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 6,
        potencia: '2.040',
        precioTotal: [
          { iva: false, precio: "2.781,51" },
          { iva: true, precio: "2.948,40" },
        ],
        precioMensual: [
          { iva: false, precio: "77,26" },
          { iva: true, precio: "81,90" },
        ],
        precioReducido: [
          { precioTotal: "3.421,00€" },
          { precioMensual: "95,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 10,
        potencia: '3.400',
        precioTotal: [
          { iva: false, precio: "4.241,89" },
          { iva: true, precio: "4.496,40" },
        ],
        precioMensual: [
          { iva: false, precio: "117,83" },
          { iva: true, precio: "124,90" },
        ],
        precioReducido: [
          { precioTotal: "5.218,00€" },
          { precioMensual: "145,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 13,
        potencia: '4.420',
        precioTotal: [
          { iva: false, precio: "5.498,49" },
          { iva: true, precio: "5.828,40" },
        ],
        precioMensual: [
          { iva: false, precio: "152,74" },
          { iva: true, precio: "161,90" },
        ],
        precioReducido: [
          { precioTotal: "6.763,00€" },
          { precioMensual: "188,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 16,
        potencia: '5.440',
        precioTotal: [
          { iva: false, precio: "6.211,70" },
          { iva: true, precio: "6.584,40" },
        ],
        precioMensual: [
          { iva: false, precio: "172,55" },
          { iva: true, precio: "182,90" },
        ],
        precioReducido: [
          { precioTotal: "7.640,00€" },
          { precioMensual: "212,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 20,
        potencia: '6.800',
        precioTotal: [
          { iva: false, precio: "7.706,04" },
          { iva: true, precio: "8.168,40" },
        ],
        precioMensual: [
          { iva: false, precio: "214,06" },
          { iva: true, precio: "226,90" },
        ],
        precioReducido: [
          { precioTotal: "9.478,00€" },
          { precioMensual: "263,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 23,
        potencia: '7.820',
        precioTotal: [
          { iva: false, precio: "8.860,75" },
          { iva: true, precio: "9.392,40" },
        ],
        precioMensual: [
          { iva: false, precio: "246,13" },
          { iva: true, precio: "260,90" },
        ],
        precioReducido: [
          { precioTotal: "10899,00€" },
          { precioMensual: "303,00€ x 36 meses" },
        ]
      },
      {
        nPaneles: 26,
        potencia: '8.840',
        precioTotal: [
          { iva: false, precio: "9.506,04" },
          { iva: true, precio: "10.076,40" },
        ],
        precioMensual: [
          { iva: false, precio: "264,06" },
          { iva: true, precio: "279,90" },
        ],
        precioReducido: [
          { precioTotal: "11692,00€" },
          { precioMensual: "325,00€ x 36 meses" },
        ]
      },
      ],
      seleccionado: {
        nPaneles: 1,
        potencia: '340',
        precioTotal: [
          { iva: false, precio: "845,66" },
          { iva: true, precio: "896,40" },
        ],
        precioMensual: [
          { iva: false, precio: "23,49" },
          { iva: true, precio: "24,90" },
        ],
        precioReducido: [
          { precioTotal: "1.040,00€" },
          { precioMensual: "29,00€ x 36 meses" },
        ]
      },
      iva: true,
    };
  },
  methods: {
    panelTexto(nPaneles) {
      if (nPaneles > 1) return " Painéis Solares";
      else return " Painel solar";
    }
  },
};
</script>
<style scoped>
.banner {
  background: url("@/assets/smart_solar_banner.webp");
  background-size: cover;
}

.titulo-card {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
}

.theme--dark.v-btn.v-btn--icon {
  color: #000000 !important;
}

.v-btn .v-btn__content .v-icon {
  color: black;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  text-align: left;
  padding: 32px 16px;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: white;
}

.flip-card-back {
  background-color: #ffffff;
  color: black;
  transform: rotateY(180deg);
}
</style>