<template>
  <div>
    <v-breadcrumbs large :items="[
      { text: 'Zirconite', to: '/', exact: true },
      { text: 'Contactos', to: '/contactos', exact: true },
    ]"></v-breadcrumbs>
    <v-row class="justify-center">
      <v-col cols="12" md="9">
        <v-card class="mx-auto pb-10" max-width="1000">
          <v-card-title class="justify-center">Morada</v-card-title>
          <v-row class="text-center">
            <v-col cols="12" class="pt-0 justify-center flex-row">
              <v-row class="py-2 justify-center">
                <v-icon>mdi-home-city</v-icon>
                <div class="ps-2" style="font-size: 13px">Rua Dr.Carlos Pires Felgueiras 98 2ºC</div>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0 justify-center flex-row">
              <v-row class="py-2 justify-center">
                <div class="ps-2" style="font-size: 13px">4470-157 Maia</div>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0 justify-center flex-row">
              <v-row class="py-2 justify-center">
                <v-icon>mdi-phone</v-icon>
                <div class="ps-2" style="font-size: 13px">223 196 190 Chamada para rede fixa nacional</div>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0 justify-center flex-row">
              <v-row class="py-2 justify-center">
                <v-icon>mdi-email</v-icon>
                <div class="ps-2" style="font-size: 13px"><a href="mailto:portugal@zirconite.pt">portugal@zirconite.pt</a></div>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mx-auto mt-5" max-width="1000" height="400px">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d187.53428011888465!2d-8.623014151828576!3d41.23160906691104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2466513e0f7f0f%3A0x148afc2df9b955c1!2sR.%20Dr.%20Carlos%20Pires%20Felgueiras%2098%202%C2%BAc%2C%204470-146%20Maia%2C%20Portugal!5e0!3m2!1ses!2ses!4v1664185289422!5m2!1ses!2ses"
            width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </v-card>
      </v-col>
    </v-row>
    <!--v-card class="mx-5 mt-5">
      <v-row>
        <v-col>IG</v-col>
        <v-col>LinkedIn</v-col>
      </v-row>
    </v-card-->
  </div>
</template>

<script>
export default {
  name: "ContactoView",
  data() {
    return {};
  },
};
</script>