<template>
  <div>
    <v-breadcrumbs
      large
      :items="[
        { text: 'Zirconite', to: '/', exact: true },
        { text: 'trabalhe connosco', disabled: true, exact: true },
      ]"
    ></v-breadcrumbs>
    <v-row
      class="mx-auto mb-3 align-center justify-space-around"
      style="max-width: 700px"
    >
      <v-col cols="12">
        <v-card
          class="mx-auto pb-5"
          style="border-top: 3px solid var(--v-primary-base)"
        >
          <v-card-title style="word-break: break-word" class="primary--text text-h4 font-weight-bold">
            Quer trabalhar connosco?
          </v-card-title>
          <v-card-text>
            Na Zirconite acreditamos que só podemos crescer de mãos dadas com os
            nossos parceiros comerciais, oferecemos formação e ferramentas para
            que todos os nossos parceiros possam desenvolver todo o seu
            potencial. Gostaria de fazer parte da nossa equipa?
            <br />
            <br />
            Envie-nos o seu CV preenchendo este simples formulário:
          </v-card-text>
          <v-form ref="form" class="mx-5">
            <v-text-field
              label="Nome*"
              outlined
              prepend-icon="mdi-card-account-details"
              :rules="nombre"
              value=""
            ></v-text-field>
            <v-text-field
              label="Email*"
              outlined
              prepend-icon="mdi-at"
              :rules="email"
              value=""
            ></v-text-field>
            <v-file-input
              truncate-length="15"
              outlined
              label="CV*"
              :rules="cv"
            ></v-file-input>
            <v-textarea
              outlined
              name="input-7-4"
              label="Mensagem"
              prepend-icon="mdi-message"
            ></v-textarea>
            <v-btn color="primary" @click="enviar" class="ms-8"> Enviar </v-btn>
            <br />
            <br />
            <text class="ms-8"> * Campos obligatorios </text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validaNombre, validaEmail } from "@/utils/rules.js";

export default {
  name: "AhorraView",
  data() {
    return {
      nombre: [(v) => validaNombre(v)],
      email: [(v) => validaEmail(v)],
      cv: [
        (v) => !!v || "Adjunta tu CV",
        (v) => (v && v.size > 0) || "Adjunta tu CV",
      ],
    };
  },
  methods: {
    async enviar() {
      if (!this.$refs.form.validate()) return;
      else {
        alert("Todo bien, eliminar alert en el despliegue");
      }
    },
  },
};
</script>